import { default as _404UNLuFnTONBMeta } from "D:/GIT/handelskontor/pages/404.vue?macro=true";
import { default as abrufscheinUCAnsyXOfEMeta } from "D:/GIT/handelskontor/pages/abrufschein.vue?macro=true";
import { default as einstellungenxCJxycjDkOMeta } from "D:/GIT/handelskontor/pages/account/einstellungen.vue?macro=true";
import { default as indexo2Gz5OnWWAMeta } from "D:/GIT/handelskontor/pages/account/index.vue?macro=true";
import { default as accountiWiDWIgPgqMeta } from "D:/GIT/handelskontor/pages/account.vue?macro=true";
import { default as anmeldenou25NY4AuhMeta } from "D:/GIT/handelskontor/pages/anmelden.vue?macro=true";
import { default as fertig9Kv3fwCnLmMeta } from "D:/GIT/handelskontor/pages/autohaus/bestandskunde/fertig.vue?macro=true";
import { default as schritt_4527p7hKXLZa6Meta } from "D:/GIT/handelskontor/pages/autohaus/bestandskunde/schritt-2.vue?macro=true";
import { default as schritt_45372nreBdduoMeta } from "D:/GIT/handelskontor/pages/autohaus/bestandskunde/schritt-3.vue?macro=true";
import { default as schritt_454uqfIbx0LAdMeta } from "D:/GIT/handelskontor/pages/autohaus/bestandskunde/schritt-4.vue?macro=true";
import { default as schritt_455MKCFI4Cr9KMeta } from "D:/GIT/handelskontor/pages/autohaus/bestandskunde/schritt-5.vue?macro=true";
import { default as schritt_456dHgWBcSYaGMeta } from "D:/GIT/handelskontor/pages/autohaus/bestandskunde/schritt-6.vue?macro=true";
import { default as schritt_4575O2DNJ18J0Meta } from "D:/GIT/handelskontor/pages/autohaus/bestandskunde/schritt-7.vue?macro=true";
import { default as schritt_458oqh7YSqwtmMeta } from "D:/GIT/handelskontor/pages/autohaus/bestandskunde/schritt-8.vue?macro=true";
import { default as indexqHOND59yIwMeta } from "D:/GIT/handelskontor/pages/autohaus/index.vue?macro=true";
import { default as fertigQQqOHlXKzvMeta } from "D:/GIT/handelskontor/pages/autohaus/neukunde/fertig.vue?macro=true";
import { default as schritt_4510tVGLMTMbUuMeta } from "D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-10.vue?macro=true";
import { default as schritt_452eHVqpaZC44Meta } from "D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-2.vue?macro=true";
import { default as schritt_453Miryjv5mQwMeta } from "D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-3.vue?macro=true";
import { default as schritt_454i1FKWhfbxaMeta } from "D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-4.vue?macro=true";
import { default as schritt_455wXbIaTIfuuMeta } from "D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-5.vue?macro=true";
import { default as schritt_456kWZRGJKIJlMeta } from "D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-6.vue?macro=true";
import { default as schritt_457g1CXT4SgwiMeta } from "D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-7.vue?macro=true";
import { default as schritt_458Db3iphuN3wMeta } from "D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-8.vue?macro=true";
import { default as schritt_459jB5AR3ebmgMeta } from "D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-9.vue?macro=true";
import { default as autohaus0FsCp891ihMeta } from "D:/GIT/handelskontor/pages/autohaus.vue?macro=true";
import { default as _91id_93Xl9xoZJbXaMeta } from "D:/GIT/handelskontor/pages/backoffice/abrufscheine/anfragen/[id].vue?macro=true";
import { default as indexC0fDtM71yQMeta } from "D:/GIT/handelskontor/pages/backoffice/abrufscheine/anfragen/index.vue?macro=true";
import { default as index0i4GzUTphkMeta } from "D:/GIT/handelskontor/pages/backoffice/abrufscheine/index.vue?macro=true";
import { default as dashboardi7XtDlyTcpMeta } from "D:/GIT/handelskontor/pages/backoffice/dashboard.vue?macro=true";
import { default as _91id_93gFN1yCZsD7Meta } from "D:/GIT/handelskontor/pages/backoffice/fahrzeugverwaltung/fahrzeuge/[id].vue?macro=true";
import { default as backup_91id_93UGUzavN3jAMeta } from "D:/GIT/handelskontor/pages/backoffice/fahrzeugverwaltung/fahrzeuge/backup[id].vue?macro=true";
import { default as indexC33K0TKmUKMeta } from "D:/GIT/handelskontor/pages/backoffice/fahrzeugverwaltung/index.vue?macro=true";
import { default as indexxJ4x6fReO0Meta } from "D:/GIT/handelskontor/pages/backoffice/fahrzeugverwaltung/marken/index.vue?macro=true";
import { default as indextSYoVy5THlMeta } from "D:/GIT/handelskontor/pages/backoffice/fahrzeugverwaltung/modelle/index.vue?macro=true";
import { default as index0rzw43PEF4Meta } from "D:/GIT/handelskontor/pages/backoffice/index.vue?macro=true";
import { default as anfragen667H5PAXEAMeta } from "D:/GIT/handelskontor/pages/backoffice/mitglieder/anfragen.vue?macro=true";
import { default as indexXoEF3M3V0KMeta } from "D:/GIT/handelskontor/pages/backoffice/mitglieder/index.vue?macro=true";
import { default as _91id_93d9dnBojUhFMeta } from "D:/GIT/handelskontor/pages/backoffice/mitglieder/nachrichten/[id].vue?macro=true";
import { default as indexsjHZmIMGAEMeta } from "D:/GIT/handelskontor/pages/backoffice/mitglieder/nachrichten/index.vue?macro=true";
import { default as _91id_9355SGMo0rM8Meta } from "D:/GIT/handelskontor/pages/backoffice/mitglieder/profil/[id].vue?macro=true";
import { default as uebersichteofbIM4LzJMeta } from "D:/GIT/handelskontor/pages/backoffice/mitglieder/uebersicht.vue?macro=true";
import { default as todosRQ2y9r0J4AMeta } from "D:/GIT/handelskontor/pages/backoffice/todos.vue?macro=true";
import { default as backofficebe7iHZu4AlMeta } from "D:/GIT/handelskontor/pages/backoffice.vue?macro=true";
import { default as datenschutzqBZpy1GCbXMeta } from "D:/GIT/handelskontor/pages/datenschutz.vue?macro=true";
import { default as firmenwagen_45finderdmdZe2J4xiMeta } from "D:/GIT/handelskontor/pages/firmenwagen-finder.vue?macro=true";
import { default as fragen_45und_45antwortenJWZo4hh38vMeta } from "D:/GIT/handelskontor/pages/fragen-und-antworten.vue?macro=true";
import { default as haendlersaITe6kUcEMeta } from "D:/GIT/handelskontor/pages/haendler.vue?macro=true";
import { default as impressumWT0WpiQtVZMeta } from "D:/GIT/handelskontor/pages/impressum.vue?macro=true";
import { default as indexEkuHeP7hbjMeta } from "D:/GIT/handelskontor/pages/index.vue?macro=true";
import { default as kontaktkzNzlbqjVrMeta } from "D:/GIT/handelskontor/pages/kontakt.vue?macro=true";
import { default as logout8RcLgJs6MqMeta } from "D:/GIT/handelskontor/pages/logout.vue?macro=true";
import { default as fertig7VoaIYKYxJMeta } from "D:/GIT/handelskontor/pages/mitglied-werden/fertig.vue?macro=true";
import { default as indexZtV0t41x4OMeta } from "D:/GIT/handelskontor/pages/mitglied-werden/index.vue?macro=true";
import { default as schritt_452VU5DWO8xB3Meta } from "D:/GIT/handelskontor/pages/mitglied-werden/schritt-2.vue?macro=true";
import { default as schritt_453XohSeG5jJQMeta } from "D:/GIT/handelskontor/pages/mitglied-werden/schritt-3.vue?macro=true";
import { default as mitglied_45werdenP2NAhSOD8BMeta } from "D:/GIT/handelskontor/pages/mitglied-werden.vue?macro=true";
import { default as top_45dealsMFXaYfmFAUMeta } from "D:/GIT/handelskontor/pages/top-deals.vue?macro=true";
import { default as ueber_45unsNn7iRPfkBJMeta } from "D:/GIT/handelskontor/pages/ueber-uns.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("D:/GIT/handelskontor/pages/404.vue")
  },
  {
    name: "abrufschein",
    path: "/abrufschein",
    meta: abrufscheinUCAnsyXOfEMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/abrufschein.vue")
  },
  {
    name: accountiWiDWIgPgqMeta?.name,
    path: "/account",
    meta: accountiWiDWIgPgqMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/account.vue"),
    children: [
  {
    name: "account-einstellungen",
    path: "einstellungen",
    meta: einstellungenxCJxycjDkOMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/account/einstellungen.vue")
  },
  {
    name: "account",
    path: "",
    meta: indexo2Gz5OnWWAMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/account/index.vue")
  }
]
  },
  {
    name: "anmelden",
    path: "/anmelden",
    meta: anmeldenou25NY4AuhMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/anmelden.vue")
  },
  {
    name: autohaus0FsCp891ihMeta?.name,
    path: "/autohaus",
    component: () => import("D:/GIT/handelskontor/pages/autohaus.vue"),
    children: [
  {
    name: "autohaus-bestandskunde-fertig",
    path: "bestandskunde/fertig",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/bestandskunde/fertig.vue")
  },
  {
    name: "autohaus-bestandskunde-schritt-2",
    path: "bestandskunde/schritt-2",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/bestandskunde/schritt-2.vue")
  },
  {
    name: "autohaus-bestandskunde-schritt-3",
    path: "bestandskunde/schritt-3",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/bestandskunde/schritt-3.vue")
  },
  {
    name: "autohaus-bestandskunde-schritt-4",
    path: "bestandskunde/schritt-4",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/bestandskunde/schritt-4.vue")
  },
  {
    name: "autohaus-bestandskunde-schritt-5",
    path: "bestandskunde/schritt-5",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/bestandskunde/schritt-5.vue")
  },
  {
    name: "autohaus-bestandskunde-schritt-6",
    path: "bestandskunde/schritt-6",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/bestandskunde/schritt-6.vue")
  },
  {
    name: "autohaus-bestandskunde-schritt-7",
    path: "bestandskunde/schritt-7",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/bestandskunde/schritt-7.vue")
  },
  {
    name: "autohaus-bestandskunde-schritt-8",
    path: "bestandskunde/schritt-8",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/bestandskunde/schritt-8.vue")
  },
  {
    name: "autohaus",
    path: "",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/index.vue")
  },
  {
    name: "autohaus-neukunde-fertig",
    path: "neukunde/fertig",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/neukunde/fertig.vue")
  },
  {
    name: "autohaus-neukunde-schritt-10",
    path: "neukunde/schritt-10",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-10.vue")
  },
  {
    name: "autohaus-neukunde-schritt-2",
    path: "neukunde/schritt-2",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-2.vue")
  },
  {
    name: "autohaus-neukunde-schritt-3",
    path: "neukunde/schritt-3",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-3.vue")
  },
  {
    name: "autohaus-neukunde-schritt-4",
    path: "neukunde/schritt-4",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-4.vue")
  },
  {
    name: "autohaus-neukunde-schritt-5",
    path: "neukunde/schritt-5",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-5.vue")
  },
  {
    name: "autohaus-neukunde-schritt-6",
    path: "neukunde/schritt-6",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-6.vue")
  },
  {
    name: "autohaus-neukunde-schritt-7",
    path: "neukunde/schritt-7",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-7.vue")
  },
  {
    name: "autohaus-neukunde-schritt-8",
    path: "neukunde/schritt-8",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-8.vue")
  },
  {
    name: "autohaus-neukunde-schritt-9",
    path: "neukunde/schritt-9",
    component: () => import("D:/GIT/handelskontor/pages/autohaus/neukunde/schritt-9.vue")
  }
]
  },
  {
    name: backofficebe7iHZu4AlMeta?.name,
    path: "/backoffice",
    meta: backofficebe7iHZu4AlMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/backoffice.vue"),
    children: [
  {
    name: "backoffice-abrufscheine-anfragen-id",
    path: "abrufscheine/anfragen/:id()",
    meta: _91id_93Xl9xoZJbXaMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/backoffice/abrufscheine/anfragen/[id].vue")
  },
  {
    name: "backoffice-abrufscheine-anfragen",
    path: "abrufscheine/anfragen",
    meta: indexC0fDtM71yQMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/backoffice/abrufscheine/anfragen/index.vue")
  },
  {
    name: "backoffice-abrufscheine",
    path: "abrufscheine",
    meta: index0i4GzUTphkMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/backoffice/abrufscheine/index.vue")
  },
  {
    name: "backoffice-dashboard",
    path: "dashboard",
    meta: dashboardi7XtDlyTcpMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/backoffice/dashboard.vue")
  },
  {
    name: "backoffice-fahrzeugverwaltung-fahrzeuge-id",
    path: "fahrzeugverwaltung/fahrzeuge/:id()",
    meta: _91id_93gFN1yCZsD7Meta || {},
    component: () => import("D:/GIT/handelskontor/pages/backoffice/fahrzeugverwaltung/fahrzeuge/[id].vue")
  },
  {
    name: "backoffice-fahrzeugverwaltung-fahrzeuge-backupid",
    path: "fahrzeugverwaltung/fahrzeuge/backup:id()",
    meta: backup_91id_93UGUzavN3jAMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/backoffice/fahrzeugverwaltung/fahrzeuge/backup[id].vue")
  },
  {
    name: "backoffice-fahrzeugverwaltung",
    path: "fahrzeugverwaltung",
    meta: indexC33K0TKmUKMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/backoffice/fahrzeugverwaltung/index.vue")
  },
  {
    name: "backoffice-fahrzeugverwaltung-marken",
    path: "fahrzeugverwaltung/marken",
    meta: indexxJ4x6fReO0Meta || {},
    component: () => import("D:/GIT/handelskontor/pages/backoffice/fahrzeugverwaltung/marken/index.vue")
  },
  {
    name: "backoffice-fahrzeugverwaltung-modelle",
    path: "fahrzeugverwaltung/modelle",
    meta: indextSYoVy5THlMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/backoffice/fahrzeugverwaltung/modelle/index.vue")
  },
  {
    name: "backoffice",
    path: "",
    component: () => import("D:/GIT/handelskontor/pages/backoffice/index.vue")
  },
  {
    name: "backoffice-mitglieder-anfragen",
    path: "mitglieder/anfragen",
    meta: anfragen667H5PAXEAMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/backoffice/mitglieder/anfragen.vue")
  },
  {
    name: "backoffice-mitglieder",
    path: "mitglieder",
    meta: indexXoEF3M3V0KMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/backoffice/mitglieder/index.vue")
  },
  {
    name: "backoffice-mitglieder-nachrichten-id",
    path: "mitglieder/nachrichten/:id()",
    meta: _91id_93d9dnBojUhFMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/backoffice/mitglieder/nachrichten/[id].vue")
  },
  {
    name: "backoffice-mitglieder-nachrichten",
    path: "mitglieder/nachrichten",
    meta: indexsjHZmIMGAEMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/backoffice/mitglieder/nachrichten/index.vue")
  },
  {
    name: "backoffice-mitglieder-profil-id",
    path: "mitglieder/profil/:id()",
    meta: _91id_9355SGMo0rM8Meta || {},
    component: () => import("D:/GIT/handelskontor/pages/backoffice/mitglieder/profil/[id].vue")
  },
  {
    name: "backoffice-mitglieder-uebersicht",
    path: "mitglieder/uebersicht",
    meta: uebersichteofbIM4LzJMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/backoffice/mitglieder/uebersicht.vue")
  },
  {
    name: "backoffice-todos",
    path: "todos",
    meta: todosRQ2y9r0J4AMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/backoffice/todos.vue")
  }
]
  },
  {
    name: "datenschutz",
    path: "/datenschutz",
    component: () => import("D:/GIT/handelskontor/pages/datenschutz.vue")
  },
  {
    name: "firmenwagen-finder",
    path: "/firmenwagen-finder",
    component: () => import("D:/GIT/handelskontor/pages/firmenwagen-finder.vue")
  },
  {
    name: "fragen-und-antworten",
    path: "/fragen-und-antworten",
    component: () => import("D:/GIT/handelskontor/pages/fragen-und-antworten.vue")
  },
  {
    name: "haendler",
    path: "/haendler",
    component: () => import("D:/GIT/handelskontor/pages/haendler.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("D:/GIT/handelskontor/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("D:/GIT/handelskontor/pages/index.vue")
  },
  {
    name: "kontakt",
    path: "/kontakt",
    component: () => import("D:/GIT/handelskontor/pages/kontakt.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logout8RcLgJs6MqMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/logout.vue")
  },
  {
    name: mitglied_45werdenP2NAhSOD8BMeta?.name,
    path: "/mitglied-werden",
    meta: mitglied_45werdenP2NAhSOD8BMeta || {},
    component: () => import("D:/GIT/handelskontor/pages/mitglied-werden.vue"),
    children: [
  {
    name: "mitglied-werden-fertig",
    path: "fertig",
    component: () => import("D:/GIT/handelskontor/pages/mitglied-werden/fertig.vue")
  },
  {
    name: "mitglied-werden",
    path: "",
    component: () => import("D:/GIT/handelskontor/pages/mitglied-werden/index.vue")
  },
  {
    name: "mitglied-werden-schritt-2",
    path: "schritt-2",
    component: () => import("D:/GIT/handelskontor/pages/mitglied-werden/schritt-2.vue")
  },
  {
    name: "mitglied-werden-schritt-3",
    path: "schritt-3",
    component: () => import("D:/GIT/handelskontor/pages/mitglied-werden/schritt-3.vue")
  }
]
  },
  {
    name: "top-deals",
    path: "/top-deals",
    component: () => import("D:/GIT/handelskontor/pages/top-deals.vue")
  },
  {
    name: "ueber-uns",
    path: "/ueber-uns",
    component: () => import("D:/GIT/handelskontor/pages/ueber-uns.vue")
  }
]